<template>
  <Section
    name="Home"
    title=" "
    class="home-section"
    :snap="false"
  >
    <div class="center">
      <img
        class="logo"
        src="../assets/rose.svg"
        alt="rose"
      >
      <div class="names">
        <span class="kelly">Kelly</span>
        <span class="and"> &amp; </span>
        <span class="frazier">Frazier</span>
      </div>
      <h3>
        Dayton, Ohio | April 30, 2022
      </h3>
      <h3
        :key="seconds"
        class="countdown"
      >
        There are {{ days }} days, {{ hours }} hours, {{ minutes }} minutes, and {{ seconds }} seconds until our wedding.
      </h3>
      <div id="nav-container">
        <Nav :items="items" />
      </div>
      <div id="photo">
        <img
          src="../assets/home-page.jpg"
          alt="Frazier and Kelly in a park"
        >
      </div>
    </div>
  </Section>
</template>

<style lang="scss" scoped>
    @import '../style/variables.scss';
    div.names {
        font-size: 2.5em;
        letter-spacing: 0.2em;
        font-family: 'Marcellus SC', serif;
        padding: 0px;
        margin: 0px;
        text-align: center;
    }
    h3 {
        font-size: 1em;
        letter-spacing: 0.2em;
        padding: 4px;
        margin: 0px;
        text-align: center;
    }
    .countdown {
        font-size: 0.75em;
        letter-spacing: 0.08vw;
    }
    span {
        padding: 0px;
        margin: 0px;
        display: inline;
    }
    .month {
        color: $home-text-month-color;
    }
    .day {
        color: $home-text-day-color;
    }
    .year {
        color: $home-text-year-color;
    }
    #photo, #photo img {
      max-height: 57vh;
      max-width: 90vw;
    }
    .logo {
      max-height: 12vh;
      max-width: 80vw;
    }
    .center {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }
</style>

<script>

const items = [
  'Home',
  'Ceremony',
  'Our Story',
  'Photography',
  'Bridal Party',
  'Travel',
  'Attractions',
  'Registry'
];

function dateData() {
  let weddingDate = Date.parse("April 30, 2022 1:30 PM EDT");
  let today = Date.now();
  let diff = weddingDate - today;
  let seconds = Math.round(diff / 1000);
  let minutes = Math.floor(seconds / 60);
  seconds -= minutes*60;
  let hours = Math.floor(minutes / 60);
  minutes -= hours*60;
  let days = Math.floor(hours / 24);
  hours -= days*24;
  return {
    days,
    hours,
    minutes,
    seconds
  };
}

import Nav from './Nav.vue';
import Section from './Section.vue';
export default {
  components: {
    Section,
    Nav
  },
  data() {
    return Object.assign({items},dateData());
  },
  created() {
    this.countdown = window.setInterval(()=>{
      let dd = dateData();
      this.days = dd.days;
      this.hours = dd.hours;
      this.minutes = dd.minutes;
      this.seconds = dd.seconds;
    }, 1000);
  },
  destroyed() {
    window.clearInterval(this.countdown);
  }
};
</script>
