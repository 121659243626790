<template>
  <div id="app">
    <div class="section-snap">
&emsp;
    </div>
    <div id="body">
      <Home />
      <Ceremony />
      <Story />
      <Photography />
      <BridalParty />
      <Travel />
      <ThingsToDo />
      <Registry />
    </div>
    <footer>Website designed and developed by Frazier Baker.<br>Copyright &copy; Frazier Baker and Kelly Krieger 2021.  All rights reserved.</footer>
  </div>
</template>

<script>
import Home from './components/Home.vue';
import Ceremony from './components/Ceremony.vue';
import Story from './components/Story.vue';
import Travel from './components/Travel.vue';
import ThingsToDo from './components/ThingsToDo.vue';
import Photography from './components/Photography.vue';
import BridalParty from './components/BridalParty.vue';
import Registry from './components/Registry.vue';
import _ from 'underscore';

export default {
  name: 'App',
  components: {
    Home,
    Ceremony,
    Story,
    Photography,
    BridalParty,
    Travel,
    ThingsToDo,
    Registry
  },
  data: function () {
    return {
    };
  }
};
</script>

<style lang="scss" global>
@import './style/variables.scss';
body, html {
  margin: 0px;
  padding:0px;
  width: 100vw;
  height: 100vh;
  scroll-snap-type: y mandatory;
  overflow-x: hidden;
  background: #eee;
}
.section-snap {
  scroll-snap-align: start;
}
footer {
  position: relative;
  bottom: 5vh;
  left: 10vw;
  text-align: center;
  font-size: 0.5em;
  height: 2vh;
  width: 80vw;
  z-index: 8;
}
a:link,a:visited,a:hover,a:active {
	color: #8c9e84;
}
</style>

<style lang="scss" global>
@import "./style/variables.scss";
@import url('https://fonts.googleapis.com/css2?family=Marcellus&family=Marcellus+SC&family=Raleway&display=swap');
#app {
  font-family: Marcellus, 'Times New Roman', Times, serif;
  width: 100vw;
  height: 100vh;
  color: $black;
}
#body {
  width: 92vw;
  position: relative;
  top: 30px;
  margin-left: 3vw;
  margin-right: 3vw;
  margin-bottom: 30px;
  border: 2px solid $black;
  outline: 4px solid $black;
  outline-offset: 0.75vw;
}
.posfix {
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 5;
}
</style>
