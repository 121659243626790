<template>
  <Section
    name="Travel"
    title="Travel"
    class="travel-section"
  >
    <div class="info">
      For our traveling guests, we recommend staying in one of the hotels at Austin Landing, approximately 2 miles away from the church and the reception.  There is a <a href="https://www.hilton.com/en/hotels/daydsgi-hilton-garden-inn-dayton-south-austin-landing/">Hilton Garden Inn (Hilton)</a> and a <a href="https://www.ihg.com/staybridge/hotels/us/en/miamisburg/daymb/hoteldetail?cm_mmc=GoogleMaps-_-SB-_-US-_-DAYMB">Staybridge Suites</a> (IHG) located in Austin Landing.  Alternatively, there are <a href="https://www.google.com/travel/hotels?utm_campaign=sharing&utm_medium=link&utm_source=htls&ts=CAESCgoCCAMKAggDEAAaVgo4EjQyJTB4ODg0MDg5MzkwNzEyYWM3ZDoweDM2ZTVmZjZlZjcyMzJlOTU6C0RheXRvbiBNYWxsGgASGhIUCgcI5g8QBBgeEgcI5g8QBRgBGAEyAggBKgsKBygBOgNVU0QaAA&rp=SAI&destination=Dayton%20Mall&ap=MAFa1wIKBQiWARAAIgNVU0QqFgoHCOUPEAoYEhIHCOUPEAoYExgBKACwAQBYAWgBcgQIAhgAmgE0EgtEYXl0b24gTWFsbBolMHg4ODQwODkzOTA3MTJhYzdkOjB4MzZlNWZmNmVmNzIzMmU5NaIBEgoIL20vMDI5Y3ISBkRheXRvbqoBCgoCCBISAghoGAGqASMKAggUEgIIHRICCBsSAwiYARICCDASAghSEgIIThICCAYYAaoBBwoDCJwBGACqAR8KAggcEgIIURICCHMSAghHEgIIJBICCE0SAwieAhgBqgESCgIIERICCEASAgg4EgIIKxgBqgEaCgIILhICCFYSAgg6EgIIPRICCEsSAghTGAGqAQYKAggsGACqAQ4KAghQEgIIGRICCEwYAaoBBgoCCAQYAKoBHgoCCDUSAggTEgIIIhICCAsSAggxEgIIXRICCEQYAZIBAiABaAA&ved=0CAAQ5JsGahcKEwiY5J_kpM_zAhUAAAAAHQAAAAAQZg">hotels near the Dayton Mall</a>, north of the reception venue.  Some of these hotels may have lower rates, though they are slightly further away.<br><br>
      For our guests arriving by plane, we recommend booking your flight to the Dayton International Airport (DAY), but if you cannot get a good flight there, you may also consider the John Glenn Columbus Airport (CMH) or the Cincinnati/Northern Kentucky International Airport (CVG).<br>
    </div>
  </Section>
</template>

<style lang="scss" scoped>
    @import '../style/variables.scss';  
    .info {
      position: relative;
      background: $info-background;
      min-height: 35vh;
    }
    .info img {
      float: right;
      width: 35vh;
      max-height:70vh;
    }
</style>

<script>
import Section from './Section.vue';
export default {
  components: {
    Section
  },
  created() {
  },
  destroyed() {
  }
};
</script>