<template>
  <div>
    <Section
      name="Registry"
      title="Registry"
      class="registry-section"
    >
      <p>Your presence, prayers, and well wishes on our wedding are gift enough, but should you wish to give us a gift as well, something from this registry or a cash gift would be greatly appreciated.</p>
      <div
        id="registry-frame"
        class="info"
      >
      <iframe src="/registry.html" />
      <a href="/registry.html" target="_blank">View our registry in a new window</a>
      </div>
    </Section>
    <div class="footer-space">
&emsp;
    </div>
  </div>
</template>

<style lang="scss" scoped>
    @import '../style/variables.scss';
    .info {
      position: relative;
      background: $info-background;
      min-height: 35vh;
      border: $black
    }
    .info iframe {
      width: 90vw;
      max-height: 68vh;
      min-height: 50vh;
    }
    .info img {
      float: right;
      width: 35vh;
    }
    .footer-space {
      width: 100vw;
      height: 4vh;
    }
    .registry-section {
      height: 90vh;
}
</style>

<script>
import Section from './Section.vue';
export default {
  components: {
    Section
  },
  created() {
  },
  destroyed() {
  }
};
</script>
