<template>
  <Section
    name="Ceremony"
    title="Ceremony"
    class="ceremony-section"
  >
    <div class="info">
      <img
        src="../assets/stmary.jpg"
        alt="St Mary of the Assumption Parish"
      >
      Our wedding will be celebrated at St. Mary of the Assumption Catholic Parish in Springboro, Ohio <!--at 1:30pm--> on April 30, 2022.  Fr. James Manning will celebrate the Holy Sacrifice of the Mass and witness our exchange of consent for the Sacrament of Matrimony.
      <br><br>St. Mary of the Assumption is located at<br>
      9579 Yankee Rd<br>
      Springboro, Ohio<br>
      45066<br/><br/>
      A reception will follow at The Golf Club at Yankee Trace.
    </div>
  </Section>
</template>

<style lang="scss" scoped>
    @import '../style/variables.scss';
    .info {
      position: relative;
      background: $info-background;
      min-height: 35vw;
    }
    .info img {
      float: right;
      max-width: 34vw;
      padding-left: 1vw;
      max-height:70vh;
    }
</style>

<script>
import Section from './Section.vue';
export default {
  components: {
    Section
  },
  created() {
  },
  destroyed() {
  }
};
</script>
