<template>
  <div>
    <div
      v-if="snap"
      class="section-snap"
    />
    <a
      class="invisible"
      :name="name.toLowerCase().replace(/ /g,'-')"
    />
    <section>
      <div
        v-if="title !== ' '"
        class="section-head"
      >
        <span class="title">{{ title ? title : name }}</span>
      </div>
      <div
        v-else
        class="invisible"
      >
&emsp;
      </div>
      <div
        :class="title === ' ' ? 'full section-body' : 'partial section-body'"
      >
        <slot />
      </div>
    </section>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default() {
        return "";
      }
    },
    title: {
      type: String,
      default() {
        return "";
      }
    },
    snap: {
      type: Boolean,
      default() {
        return true;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../style/variables.scss";
section {
    height: 95vh;
    margin-bottom: 3vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.section-top {
  width: 100vw;
  height: 2em;
}
.title {
    font-size: 2em;
}
.section-head, .section-body {
    width: 90vw;
    padding-top: 1vh;
    padding-bottom: 1vh;
    padding-left: 1vw;
    padding-right: 1vw;
    font-size: 1em;
}
.section-head {
  height: 10%;
}
.section-body.full {
  height: 100%;
}
.section-body.partial {
  height: 80%;
}
.invisible {
  font-size: 0px;
}
</style>
