<template>
  <Section
    name="Our Story"
    title="Our Story"
    class="story-section"
  >
    <div class="info">
      <img
        v-if="widescreen"
        src="../assets/tree_full.jpg"
        alt="Kelly and Frazier under Tree"
      >
      <p :class="{widescreen: widescreen}">
        Kelly and Frazier met on February 2, 2018 at a banquet celebrating the 25th anniversary of the merging of the St. Monica and St. George parishes. Sitting at the same table Kelly and Frazier chatted a little bit on and off. It was not until the dancing started and Frazier asked Kelly to dance that Kelly and Frazier began to connect. Before the night was over Kelly asked for Frazier’s number.
      </p>
      <p :class="{widescreen: widescreen}">
        The very next day Kelly texted Frazier. They chatted for a little bit then connected even more. Frazier invited Kelly over for dinner and swing dancing. They talked over dinner that Frazier made Kelly and then Frazier tried his best to teach Kelly how to swing dance. From this point Kelly and Frazier spent almost all of their time together going on walks and going to church together before officially entering a relationship. They have been together ever since.
      </p>
      <p :class="{widescreen: widescreen}">
        Frazier proposed to Kelly during a spontaneous trip to the Abbey of Gethsemani in Kentucky. While hiking and ascending what is known as Tents’s Knob. Frazier and Kelly made it to the top where Frazier got down on one knee and asked Kelly to marry him and Kelly said, “Yes.”
      </p>
    </div>
  </Section>
</template>

<style lang="scss" scoped>
    @import '../style/variables.scss';
    .info {
      position: relative;
      background: $info-background;
      min-height: 35vh;
    }
    .info p {
      font-size: 0.75em;
    }
    .info p.widescreen {
      font-size: 1em;
    }
    .info img {
      float: right;
      max-width: 34vh;
      padding-left: 1vw;
      max-height: 70vh;
    }
</style>

<script>
import Section from './Section.vue';
export default {
  components: {
    Section
  },
  data() {
    return {
      widescreen: window.innerWidth > 1024,
    };
  },
  mounted() {
    window.addEventListener('resize', this.resize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.resize);
  },
  created() {
  },
  destroyed() {
  },
  methods: {
    resize() {
      this.widescreen = window.innerWidth > 1024;
    }
  }
};
</script>