<template>
  <nav v-if="widescreen">
    <a
      v-for="item in items"
      :key="'link-'+item"
      class="navitem"
      :href="'#'+item.toLowerCase().replace(/ /g,'-')"
    >{{ item }}</a>
  </nav>
  <div
    v-else
  >
    <div>
      <nav
        id="littlelogo"
        class="smallnav mobilenav"
        @mouseover="open"
        @mouseout="close"
      >
        K+F
      </nav>
      <div
        v-show="!closed"
        class="drawer"
        @mouseover="open"
        @mouseout="close"
        @mouseup="close"
      >
        <a
          v-for="item in items"
          :key="'option-'+item"
          :href="'#'+item.toLowerCase().replace(/ /g,'-')"
        >
          {{ item }}
        </a>
        <a href="/faq">
          FAQ
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'underscore';

const animationIntervals = [
  "K+F",
  "Ke+Fra",
  "Kel+Fraz",
  "Kell+Frazi",
  "Kelly+Frazier"
];

export default {
  props: {
    items: {
      type: Array,
      default() { return []; }
    }
  },
  data() {
    return {
      closed: true,
      widescreen: window.innerWidth > 1024
    };
  },
  mounted() {
    window.addEventListener('resize', this.resize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.resize);
  },
  methods: {
    resize() {
      this.widescreen = window.innerWidth > 1024;
    },
    open() {
      this.closed = false;
      this.hoverIn();
    },
    close() {
      this.closed = true;
      this.hoverOut();
    },
    hoverIn() {
      let element = document.getElementById("littlelogo");
      let text = element.innerText;
      let interval = animationIntervals.findIndex((x) => x == text);
      if(interval < (animationIntervals.length - 1) && !this.closed) {
        element.innerText = animationIntervals[Math.min(interval + 1, animationIntervals.length-1)];
      }
      if((interval + 1) < (animationIntervals.length-1)) {
        window.setTimeout(this.hoverIn, 50);
      }
    },
    hoverOut() {
      let element = document.getElementById("littlelogo");
      let text = element.innerText;
      let interval = animationIntervals.findIndex((x) => x == text);
      if(interval > 0 && this.closed) {
        element.innerText = animationIntervals[Math.max(interval - 1, 0)] ;
      }
      if((interval - 1) > 0) {
        window.setTimeout(this.hoverOut, 50);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../style/variables.scss";
nav {
  width: 80vw;
  font-size: 1.25em;
  display: flex;
  justify-content: space-between;
  height: 4vh;
  padding-left: 2vw;
  padding-right: 2vw;
  background: $navbar-background;
  margin: 5px;
}
nav.smallnav {
    justify-content: center;
}
nav.mobilenav {
    position: fixed;
    top: 0px;
    z-index: 500;
    left: 0px;
    width: 100vw;
    margin: 0px;
    padding: 0px;
    z-index: 500;
}
.navitem {
  text-decoration: none;
  color: $navbar-text;
}
.navitem:hover {
  color: $navbar-text-hover;
  border-bottom: 1px solid $navbar-text-hover;
}
.drawer {
    width: 50vw;
    position: fixed;
    top: 4vh;
    left: 25vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.25em;
    background: $navbar-background;
    z-index: 500;
}
.drawer a:link, .drawer a:hover, .drawer a:visited, .drawer a:active {
    background: $drawer-background;
    width: 100%;
    text-align: center;
    text-decoration: none;
    font-size: 1.25em;
}
</style>
