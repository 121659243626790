<template>
  <Section
    name="Photography"
    title="Photography"
    class="photo-section"
  >
    <div class="scrollgal">
      <div :class="{gallery: true, mobile: !widescreen}">
        <img
          v-for="slide in slides"
          :key="slide.image"
          :src="slide.image"
          @click="view(slide.image)"
        >
      </div>
    </div>
    <div
      v-if="megaview"
      :class="{mobile: !widescreen, megaview: true}"
      @click="unview()"
    >
      <img :src="megasrc">
    </div>
  </Section>
</template>

<style lang="scss" scoped>
    @import '../style/variables.scss';
    .scrollgal {
      overflow-y: scroll;
      overflow-x: none;
    }
    .gallery {
      position: relative;
      background: $info-background;
      height: 75vh;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-content: flex-start;
      width: 85vw;
      align-items: space-around;
      justify-items: space-around;
      flex-wrap: wrap;
    }
    .gallery img {
      height: 70vh;
      padding-bottom: 2vh;
      width: auto;
    }
    .gallery.mobile img {
      width: 95vw;
      height: auto;
    }
    .megaview {
      position: fixed;
      top:0px;
      left:0px;
      height: 100vh;
      width: 100vw;
      z-index: 1170;
      background: transparentize($black, 0.2);
      display: flex;
      justify-content: center;
    }
    .megaview img {
      object-fit: contain;
    }
    .megaview.mobile img  {
      width: 100vw;
      object-fit: scale-down;
    }
</style>

<script>
import Section from './Section.vue';

const slides = [
  {"title":"","image": "../photos/103.jpg"},
  {"title":"","image": "../photos/110.jpg"},
  {"title":"","image": "../photos/114.jpg"},
  {"title":"","image": "../photos/125.jpg"},
  {"title":"","image": "../photos/139.jpg"},
  {"title":"","image": "../photos/144.jpg"},
  {"title":"","image": "../photos/153.jpg"},
  {"title":"","image": "../photos/159.jpg"},
  {"title":"","image": "../photos/160.jpg"},
  {"title":"","image": "../photos/169.jpg"},
  {"title":"","image": "../photos/170.jpg"},
  {"title":"","image": "../photos/171.jpg"},
  {"title":"","image": "../photos/175.jpg"},
  {"title":"","image": "../photos/177.jpg"},
  {"title":"","image": "../photos/191.jpg"},
  {"title":"","image": "../photos/197.jpg"}
];

export default {
  components: {
    Section,
  },
  data() {
    console.log(window.innerWidth);
    return {
      slides,
      megaview: false,
      widescreen: window.innerWidth > 1024,
    };
  },
  mounted() {
    window.addEventListener('resize', this.resize);
  },
  created() {
  },
  destroyed() {
  },
  methods: {
    resize() {
      this.widescreen = window.innerWidth > 1024;
    },
    view(image) {
      this.megasrc = image;
      this.megaview = true;
    },
    unview() {
      this.megaview = false;
    }
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.resize);
  }
};
</script>