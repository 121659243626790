<template>
  <Section
    name="Attractions"
    title="Attractions"
    class="things-to-do-section"
  >
    <div class="info">
      If you are looking for fun activities nearby, we have some recommendations from around the area:
      <div
        v-if="widescreen"
        class="tags-toggler"
      >
        <span
          v-for="tag in possibleTags"
          :key="'ptaglist-'+tag"
          :class="['tag', activeTags.indexOf(tag) >= 0 ? 'active-tag' : 'inactive-tag']"
          @click="toggle"
        >{{ tag }}</span>
      </div>
      <div class="table-container">
        <table>
          <thead>
            <tr>
              <th>Attraction</th>
              <th>Distance</th>
              <th v-if="widescreen">
                Admission
              </th>
              <th v-if="widescreen" />
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="thing in activeThings"
              :key="thing.Title"
            >
              <td>
                <a
                  :href="thing.URL"
                  target="_blank"
                >{{ thing.Title }}</a>
              </td>
              <td>{{ thing.Distance }} mi</td>
              <td v-if="widescreen">
                {{ (thing.Admission === "Varies" ? thing.Admission : "$" + thing.Admission + ".00").replace("$0.00", "FREE") }}
              </td>
              <td v-if="widescreen">
                <span
                  v-for="tag in thing.Tags"
                  :key="thing.Title + tag"
                  :class="[tag, 'tag']"
                >{{ tag }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </Section>
</template>

<style lang="scss" scoped>
    @import '../style/variables.scss';  
    .info {
      position: relative;
      background: $info-background;
      min-height: 35vh;
    }
    .info img {
      float: right;
      width: 35vh;
    }
    .tags-toggler {
      min-height: 80px;
      width: 60vw;
    }
    .tags-toggler span{
      float: left;
    }
    span.tag.active-tag {
      border: 2px solid black;
    }
    span.tag.inactive-tag {
      border: 2px solid #AAA;
      color: #AAA;
      background: #FFF;
    }
    .table-container {
      a:link, a:visited, a:active {
        text-decoration: underline;
        color: $black;
      }
      a:hover {
        text-decoration: underline;
        color: darken($sage, 30%);
      }
    }
    .table-container {
      height: 50vh;
      overflow-y: scroll;
      overflow-x: scroll;
    }
    .table-container thead th {
      position: sticky;
      top: 0px;
      background:#eee;
      padding: 10px;
      font-size: 1.5em;
      border-bottom: 2px black solid;
    }
    .table-container tr {
      height: 30px;
    }
    .table-container tr:nth-child(even) {
      background-color: $table-even;
    }
    .table-container tr:hover {
      background-color: $table-hover;
    }
    .tag {
      padding: 2px;
      padding-left: 10px;
      padding-right: 10px;
      margin: 4px;
      border: black 1px solid;
    }
    /*
    .Historical {
      background: #979790;
    }
    .Art {
      background: #AC88F7;
    }
    .Museum {
      background: #944449;
      color: white;
    }
    .Golf {
      background: #90DD88;
    }
    .Outdoor {
      background: #00AA00;
    }
    .Technology {
      background: #999999;
    }
    .Indoor {
      background: #F0A088;
    }
    .Park {
      background: #11DD11;
    }
    .Hiking {
      background: #978644;
    }
    .Lake {
      background: #47478E;
      color: white;
    }
    .River {
      background: #6968EE;
      color: white;
    }
    .Shopping {
      background: #FD88FD;
    }
    .Restaurants {
      background: #FF2626;
    }
    .Bar {
      background: #DFDF00;
    }
    .Games {
      background: #990099;
      color: white;
    }
    .Trampoline {
      background: black;
      color: white;
    }
    .Arcade {
      background: #00FFFF;
    }
    .Rides {
      background: #008080;
      color: white;
    }
    .Farm {
      background: #97F800;
    }
    .Antiques {
      background: #FFDF90;
    }
    .Music {
      background: #4422FF;
      color: white;
    }
    .Concert {
      background: #453011;
      color:white;
    }*/
</style>

<script>
import Section from './Section.vue';
export default {
  components: {
    Section
  },
  data() {
    const possibleTags = [
      "Historical",
      "Museum",
      "Park",
      "Bar",
      "Restaurants",
      "Outdoor",
      "Indoor",
      "Golf",
      "Hiking",
      "Trampoline",
      "Music",
      "Concert",
      "Art",
      "Technology",
      "Farm",
      "Shopping",
      "Rides",
      "Antiques"
    ];
    const things = [
      {
        "Title": "Carillon Historical Park",
        "Admission": "12",
        "Distance": 12.7,
        "Tags": "Historical,Museum,Park".split(','),
        "URL": "https://www.daytonhistory.org/visit/dayton-history-sites/carillon-historical-park/"
      },
      {
        "Title": "National Museum of the United States Air Force",
        "Admission": "0",
        "Distance": 18.8,
        "Tags": "Historical,Museum,Technology,Indoor".split(','),
        "URL": "https://www.nationalmuseum.af.mil/"
      },
      {
        "Title": "Dayton Art Institute",
        "Admission": "15",
        "Distance": 15,
        "Tags": "Art,Museum,Indoor".split(','),
        "URL": "https://www.daytonartinstitute.org/"
      },
      {
        "Title": "Yankee Trace Golf Club",
        "Admission": "35",
        "Distance": 0,
        "Tags": "Golf,Outdoor".split(','),
        "URL": "https://www.yankeetrace.org/"
      },
      {
        "Title": "Mound Cold War Discovery Center",
        "Admission": "0",
        "Distance": 6.8,
        "Tags": "Historical,Museum,Technology,Indoor,Park".split(','),
        "URL": "https://moundmuseum.com/"
      },
      {
        "Title": "Young's Jersey Dairy",
        "Admission": "0",
        "Distance": 29.2,
        "Tags": "Farm,Outdoor".split(','),
        "URL": "https://youngsdairy.com/"
      },
      {
        "Title": "Kings Island",
        "Admission": "40",
        "Distance": 20.3,
        "Tags": "Outdoor,Park,Rides".split(','),
        "URL": "https://www.visitkingsisland.com"
      },
      {
        "Title": "Caesar Creek State Park",
        "Admission": "0",
        "Distance": 14.2,
        "Tags": "Outdoor,Park,Hiking,Lake".split(','),
        "URL": "https://ohiodnr.gov/wps/portal/gov/odnr/go-and-do/plan-a-visit/find-a-property/caesar-creek-state-park"
      },
      {
        "Title": "Sugarcreek Metropark",
        "Admission": "0",
        "Distance": 7.3,
        "Tags": "Outdoor,Park,Hiking,River".split(','),
        "URL": "https://www.metroparks.org/places-to-go/sugarcreek/"
      },
      {
        "Title": "Scene 75 Dayton",
        "Admission": "0",
        "Distance": 20.5,
        "Tags": "Arcade,Indoor,Games".split(','),
        "URL": "https://www.scene75.com/dayton/"
      },
      {
        "Title": "TopGolf West Chester",
        "Admission": "40",
        "Distance": 25.8,
        "Tags": "Golf,Bar".split(','),
        "URL": "https://topgolf.com/us/west-chester/"
      },
      {
        "Title": "Boonshoft Museum of Discovery",
        "Admission": "15",
        "Distance": 16.6,
        "Tags": "Indoor,Museum,Technology".split(','),
        "URL": "http://www.boonshoftmuseum.org/"
      },
      {
        "Title": "SkyZone Dayton",
        "Admission": "25",
        "Distance": 3.3,
        "Tags": "Indoor,Trampoline".split(','),
        "URL": "https://www.skyzone.com/dayton"
      },
      {
        "Title": "Austin Landing",
        "Admission": "0",
        "Distance": 2.5,
        "Tags": "Shopping,Restaurants".split(','),
        "URL": "https://austinlanding.com/"
      },
      {
        "Title": "The Greene",
        "Admission": "0",
        "Distance": 11.2,
        "Tags": "Shopping,Restaurants,Outdoor".split(','),
        "URL": "https://www.thegreene.com/directory/"
      },
      {
        "Title": "The Dayton Mall",
        "Admission": "0",
        "Distance": 3.8,
        "Tags": "Shopping,Restaurants,Indoor".split(','),
        "URL": "https://daytonmall.com/"
      },
      {
        "Title": "Rose Music Center",
        "Admission": "Varies",
        "Distance": 29.2,
        "Tags": "Music,Concert".split(','),
        "URL": "https://www.rosemusiccenter.com/"
      },
      {
        "Title": "Antiques Village",
        "Admission": "0",
        "Distance": 2.9,
        "Tags": "Shopping,Antiques".split(','),
        "URL": "https://www.antiquesvillage.net/"
      }
    ];
    return {
      widescreen: window.innerWidth > 1024,
      possibleTags: possibleTags,
      activeTags: possibleTags,
      things: things,
      activeThings: things
    };
  },
  mounted() {
    window.addEventListener('resize', this.resize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.resize);
  },
  created() {
  },
  destroyed() {
  },
  methods: {
    resize() {
      this.widescreen = window.innerWidth > 1024;
    },
    toggle(ev) {
      let tag = ev.target.innerText;
      console.log(tag);
      console.log(this.activeTags);
      if(this.activeTags.indexOf(tag) >= 0) {
        this.activeTags = this.activeTags.filter((atag) => {
          return atag !== tag;
        });
      } else {
        this.activeTags.push(tag);
      }
      this.activeThings = this.things.filter((active) => {
        return active.Tags.some((tag) => this.activeTags.indexOf(tag) >= 0);
      });
    }
  }
};
</script>