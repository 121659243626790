<template>
  <Section
    name="Bridal Party"
    title="Bridal Party"
    class="bridal-party"
  >
    <div :class="{mobile: !widescreen, info: true}">
      <div class="column">
        <h3>Maid of Honor</h3>
        <h4>Jill Catherine Krieger</h4>
        <p>Jill is Kelly’s middle sister.  Jill and Kelly have so many great memories growing up as sisters; riding bikes up and down their street, cheering each other on at swim meets, and being the best sisters they can to each other.  Jill forges her own path, is dedicated, and a hard worker with everything she sets her mind to.</p>
        <h3>Bridesmaids</h3>
        <h4>Meagan Armstrong</h4>
        <p>During a Fall Retreat with the University of Cincinnati Newman center Kelly and Meagan’s paths crossed. Kelly came to know Meagan to be a dependable and compassionate individual and friend. Meagan and Kelly later went to a semi-spooky haunted house, became a part of the leadership committee for the Newman Center, and finished their undergrad time at the University of Cincinnati with the senior retreat. </p>
        <h4>Claire Cuipak</h4>
        <p>Claire and Kelly first met as student orientation leaders for the University of Cincinnati Blue Ash (UCBA). Their friendship grew as they learned an orientation dance and became ambassadors for UCBA. Claire and Kelly took physics lab at night and with the help of each other they survived physics! Over the years Kelly and Claire’s friendship has only gotten stronger. Claire truly is a brilliant and loyal friend.</p>
        <h4>Megan Krieger</h4>
        <p>Megan is Kelly’s youngest sister. Megan and Kelly have cherished their time together growing up: playing in the creek near their house, supporting each other as athletes, and forming a close sister bond that will last a lifetime. Megan is devoted to her task at hand and once she accomplishes one thing she is ready to move onto the next.</p>
        <h4>Morgan Martini</h4>
        <p>Growing up as cousins Morgan and Kelly spent tons of time together having cousin sleepovers, jumping on the bounce around , and swinging on swings while singing rockin 'around the christmas tree. To this day Morgan and Kelly are very close and always support each other. Morgan’s love for Disney is only rivaled by the love she has for those she cares about.</p>
        <h4>Marie Schneider</h4>
        <p>At a University of Cincinnati Newman center movie night Kelly and Marie met. Kelly sat next to Marie and they started talking. Movie night evolved into both of them being part of the leadership committee for Newman center events and taking part in a Newman center camping trip. Kelly and Marie share a love for cross stitching. Not only does Marie have some incredible cross stitching skills she is a considerate and genuine friend.</p>
      </div>
      <div class="column">
        <h3>Best Man</h3>
        <h4>Christopher Alan Stone</h4>
        <p>Chris and Frazier were randomly-assigned roommates during their freshman year at the University of Cincinnati, perhaps the best random roommate assignment in University history. During this year, they forged a lifelong friendship. They continued to live together for the next five years at school and both enjoy video games, board games, D&D, and their faith.  When you put Frazier and Chris together, you can always expect them to generate entertaining solutions to the world's problems.</p>
        <h3>Groomsmen</h3>
        <h4>Matthew Baker</h4>
        <p>Matt is Frazier's cousin.  Growing up, Frazier always thought that Matt had the coolest Pokemon cards and video games.  Matt was part of the reason Frazier first considered going to the University of Cincinnati, where Frazier would eventually meet Kelly.</p>
        <h4>Zachary Baker</h4>
        <p>Zach is Frazier's younger brother.  Through their childhood together, Zach and Frazier taught each other so much about life and how to understand people.  Zach and Frazier enjoy taking drives together, playing video games, and swimming during the summer.</p>
        <h4>Jeremiah Greer</h4>
        <p>Jeremy and Frazier met at the University of Cincinnati during their freshman year.  They were both Computer Science majors who quickly bonded over their shared interests.  They spent the summer after their freshman year as roommates while pursuing research opportunities on campus.</p>
        <h4>David Tangeman</h4>
        <p>David and Frazier met through the Newman Center at the University of Cincinnati, but they really started to bond towards the end of their time at UC.  David and Frazier both enjoy hiking, video games, sharing marching band stories, and discussing topics of faith.</p>
        <h4>Austin Wayne</h4>
        <p>Austin and Frazier met in Calculus I at Ohio University Zanesville.  They quickly bonded over their passion for mathematics and later worked together as tutors in OUZ's Student Success Center.  They played Minecraft and Dungeons and Dragons together, and stayed in touch even after Frazier left for UC.</p>
      </div>
    </div>
  </Section>
</template>

<style lang="scss" scoped>
    @import '../style/variables.scss';
    .info {
      position: relative;
      background: $info-background;
      height: 70vh;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: flex-start;
      flex-wrap: wrap;
      overflow-y: scroll;
      overflow-x: hidden;
    }
    .info .column {
        width: 30vw;
    }
    .info.mobile {
        flex-direction: column;
        justify-content: flex-start;
        flex-wrap: unset;
    }
    .info.mobile .column {
        width: 80vw;
    }
    .column h3 {
        font-family: 'Marcellus SC', sans;
        font-size: 2em;
    }
</style>

<script>
import Section from './Section.vue';
export default {
  components: {
    Section
  },
  data() {
    return {
      widescreen: window.innerWidth > 1024,
    };
  },
  mounted() {
    window.addEventListener('resize', this.resize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.resize);
  },
  created() {
  },
  destroyed() {
  },
  methods: {
    resize() {
      this.widescreen = window.innerWidth > 1024;
    }
  }
};
</script>
